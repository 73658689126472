<template>
  <ModalMainNew
    modal-width-mode="3xl"
    show-close-mobile
    secondary-background-color
    @close="onCloseModal"
  >
    <div class="animate-slide-down-fade-in01s">
      <h2 class="text-xl font-bold text-center mb-5 uppercase">
        Verifiably Fair
      </h2>
      <h3 class="text-lg font-bold mb-4 uppercase">
        Summary
      </h3>
      <p class="text-gray-400 text-sm mb-5">
        The prices for each trading round are determined in advance using a randomly drawn seed, which is revealed at the conclusion of the round.
        Once the seed is published, anyone can verify that the pricing algorithm was adhered to and that no prices were altered after the trading round began.
        This mechanism guarantees that prices cannot be manipulated based on the outcome of user bets.
      </p>
      <h3 class="text-lg font-bold mb-4 uppercase">
        Previous Rounds
      </h3>
      <div class="flex flex-wrap justify-start gap-0 mb-2">
        <div class="basis-1/2 md:basis-1/4">
          <label class="text-white text-xs font-semibold uppercase">
            Name:
          </label>
          <span class="text-gray-400 text-sm pl-1">
            {{ tradingVerifiablyFairModalData.name }}
          </span>
        </div>
        <div class="basis-1/2 md:basis-1/4">
          <label class="text-white text-xs font-semibold uppercase grow">
            Duration:
          </label>
          <span class="text-gray-400 text-sm pl-1">
            {{ roundDuration }}
          </span>
        </div>
        <div class="basis-1/2 md:basis-1/4">
          <label class="text-white text-xs font-semibold uppercase">
            Volatility:
          </label>
          <span class="text-gray-400 text-sm pl-1">
            {{ tradingVerifiablyFairModalData.volatility }}%
          </span>
        </div>
        <div class="basis-1/2 md:basis-1/4">
          <label class="text-white text-xs font-semibold uppercase">
            Reset:
          </label>
          <span class="text-gray-400 text-sm pl-1">
            {{ $dayjs.utc(tradingVerifiablyFairModalData.roundResetTime).format('HH:mm:ss') }} UTC
          </span>
        </div>
      </div>
      <div class="flex-1 overflow-x-auto mb-5">
        <ListTradingRoundHistory
          :asset-code="tradingVerifiablyFairModalData.code"
          selectable
          @select="onRoundSelected"
        />
      </div>
      <h3 class="text-lg font-bold mb-4 uppercase">
        Pricing Algorithm
      </h3>
      <p class="text-gray-400 text-sm mb-4">
        At the commencement of each trading session, a fresh 32-byte random seed is generated.
        This seed, when combined with an integer tick counter that starts at zero and increments by one, enables the deterministic reproduction of all price movements for that session.
      </p>
      <p class="text-gray-400 text-sm mb-4">
        The procedure is as follows:
      </p>
      <ol class="text-gray-400 text-sm list-decimal pl-4 mb-5">
        <li
          v-for="(item, index) in PRICING_ALGORITHM_ITEMS"
          :key="index"
        >
          <span class="font-semibold text-slate-50">{{ item.title }}</span>:
          {{ item.content }}
        </li>
      </ol>
      <h3 class="text-lg font-bold mb-2 uppercase">
        Verification
      </h3>
      <p class="text-sm text-gray-400 mb-4">
        You can verify the prices from a trading round once the seed has been revealed. As an example, the following JavaScript code calculates the prices of a rounds seed - you can change some of the parameters below or click on of the rounds above to try it out:
      </p>
      <PanelTradingVerifier
        :seed="selectedRound?.seed"
        :volatility="tradingVerifiablyFairModalData.volatility"
        :start-price="selectedRound?.price.start"
      />
    </div>
  </ModalMainNew>
</template>

<script setup>
const uiStore = useUiStore();

const {
  showTradingVerifiablyFairModal,
  tradingVerifiablyFairModalData,
} = storeToRefs(uiStore);

const {
  $dayjs,
} = useNuxtApp();

const PRICING_ALGORITHM_ITEMS = [
  {
    title: 'Seed Encoding',
    content: 'The 32-byte seed is converted into a 64-character hexadecimal string using lowercase letters.',
  },
  {
    title: 'Concatenation',
    content: 'This hexadecimal seed string is concatenated with the string representation of the current tick counter value.',
  },
  {
    title: 'Hash Computation',
    content: 'The combined string is hashed using the BLAKE2b cryptographic hash function with a 32-byte digest size.',
  },
  {
    title: 'Integer Conversion',
    content: 'The first 8 bytes of the hash output are extracted and interpreted as an unsigned 64-bit integer in little-endian format.',
  },
  {
    title: 'Normalization',
    content: 'This integer is normalized to a floating-point number uniformly distributed in the interval [0, 1] by dividing it by 264 - 1.',
  },
  {
    title: 'Return Calculation',
    content: 'The normalized floating-point number is transformed into a normally distributed return by applying the inverse cumulative distribution function of the normal distribution. The standard deviation used in this transformation is set to the market’s configured tick volatility, with a mean of zero.',
  },
  {
    title: 'Price Update',
    content: 'The calculated normally distributed return is applied to update the market price accordingly.',
  },
];

const selectedRound = ref(null);
const roundDuration = ref(tradingVerifiablyFairModalData.value.roundDurationType === 'day' ? '24 Hour' : '1 Hour');

function onRoundSelected(round) {
  selectedRound.value = round;
}

function onCloseModal() {
  showTradingVerifiablyFairModal.value = false;
  tradingVerifiablyFairModalData.value = null;
}
</script>

<template>
  <div class="text-gray-400 text-sm">
    <div class="overflow-auto bg-slate-800 p-4 rounded-lg">
      <pre class="text-xs text-white min-w-[600px]">
        <code class="whitespace-pre-wrap"><span class="text-purple-400">import</span> jstat <span class="text-purple-400">from</span> <span class="text-yellow-300">'jstat'</span>;
        <span class="text-purple-400">import</span> blakejs <span class="text-purple-400">from</span> <span class="text-yellow-300">'blakejs'</span>;
        <span class="text-purple-400">import</span> { Buffer } <span class="text-purple-400">from</span> <span class="text-yellow-300">'buffer'</span>;

        <p class="inline-flex items-center w-full"><span class="text-blue-400">const</span> seed = <span class="text-yellow-300 inline-flex flex-1 max-w-[480px]">'<input id="seedInput" v-model="seed" type="text" class="bg-slate-900 my-[1px] w-full border-transparent focus:bg-gray-700 focus:border-gray-500 outline-none focus:ring-0 text-yellow-300e px-[5px] py-0 rounded inline-block text-xs">'</span>;</p>
        <span class="text-blue-400">let</span> price = <input id="priceInput" v-model="price" type="number" class="bg-slate-900 my-[1px] border-transparent focus:bg-gray-700 focus:border-gray-500 outline-none focus:ring-0 text-green-400 px-[5px] py-0 rounded w-20 inline-block text-xs">;
        <span class="text-blue-400">const</span> volatility = <input id="volatilityInput" v-model="volatility" type="number" step="0.001" class="bg-slate-900 my-[1px] border-transparent focus:bg-gray-700 focus:border-gray-500 outline-none focus:ring-0 text-green-400 px-[5px] py-0 rounded w-20 inline-block text-xs">;
        <span class="text-blue-400">const</span> totalTicks = <input id="totalTicks" v-model="totalTicks" type="number" step="0.001" class="bg-slate-900 my-[1px] border-transparent focus:bg-gray-700 focus:border-gray-500 outline-none focus:ring-0 text-green-400 px-[5px] py-0 rounded w-20 inline-block text-xs">;

        <span class="text-white">console</span>.<span class="text-blue-400">log</span>(<span class="text-green-400">`Tick: 0: Price: <span class="text-white">${price.<span class="text-blue-400">toFixed(<span class="text-green-400">2</span>)</span>}</span>`</span>);

        <span class="text-purple-400">for</span> (<span class="text-blue-400">let</span> i = <span class="text-green-400">0</span>; i &lt; <span class="text-white">totalTicks</span>; i++) {
          <span class="text-blue-400">const</span> combinedValue = <span class="text-purple-400">Buffer</span>.<span class="text-blue-400">from</span>(seed + i, <span class="text-yellow-300">'ascii'</span>);
          <span class="text-blue-400">const</span> context = blakejs.<span class="text-blue-400">blake2bInit</span>(<span class="text-green-400">32</span>);
          blakejs.<span class="text-blue-400">blake2bUpdate</span>(context, combinedValue);
          <span class="text-blue-400">const</span> hash = blakejs.<span class="text-blue-400">blake2bFinal</span>(context);

          <span class="text-blue-400">let</span> unsignedInt = <span class="text-green-400">0n</span>;
          <span class="text-purple-400">for</span> (<span class="text-blue-400">let</span> i = <span class="text-green-400">0</span>; i &lt; <span class="text-green-400">8</span>; i++) {
            unsignedInt += <span class="text-purple-400">BigInt</span>(hash[i]) &lt;&lt; <span class="text-purple-400">BigInt</span>(<span class="text-green-400">8</span> * i);
          }

          <span class="text-blue-400">const</span> unitRangeFloat = <span class="text-purple-400">Number</span>(unsignedInt) / <span class="text-purple-400">Number</span>(<span class="text-green-400">2n ** 64n - 1n</span>);
          <span class="text-blue-400">const</span> normalReturn = jstat.normal.<span class="text-blue-400">inv</span>(unitRangeFloat, <span class="text-green-400">0</span>, volatility);
          price = price * (<span class="text-green-400">1.0</span> + normalReturn);

          <span class="text-white">console</span>.<span class="text-blue-400">log</span>(<span class="text-green-400">`Tick: <span class="text-white">${i + <span class="text-green-400">1</span>}</span>: Price: <span class="text-white">${price.<span class="text-blue-400">toFixed(<span class="text-green-400">2</span>)</span>}</span>`</span>);
        }</code>
      </pre>
    </div>
    <p class="my-2">
      Output:
    </p>
    <code class="bg-slate-800 p-4 rounded-lg overflow-auto text-xs text-white block max-h-64 overflow-y-auto mb-4">
      <div v-for="item in output" :key="item.tick">
        <span class="text-purple-400">Tick:</span> {{ item.tick }} <span class="text-purple-400">Price:</span> {{ item.price }}
      </div>
    </code>
  </div>
</template>

<script setup>
import { Buffer } from 'buffer';
import blakejs from 'blakejs';
import jstat from 'jstat';
import { useDebounceFn } from '@vueuse/core';

const props = defineProps({
  seed: {
    type: String,
    default: 'dec65d890001a9dc1a5e1da29a02c782752ef820eac517dbe2e8796b705fd360',
  },
  volatility: {
    type: Number,
    default: 0.001,
  },
  startPrice: {
    type: Number,
    default: 1000,
  },
});

const seed = ref(props.seed);
const price = ref(props.startPrice);
const volatility = ref(props.volatility);
const totalTicks = ref(5);
const output = ref([]);

function getOutput() {
  if (totalTicks.value > 172800) {
    totalTicks.value = 172800;
  }

  const results = [];

  let currentPrice = price.value;

  results.push({
    tick: 0,
    price: currentPrice.toFixed(2),
  });

  for (let i = 0; i < totalTicks.value; i++) {
    const combinedValue = Buffer.from(seed.value + i, 'ascii');
    const context = blakejs.blake2bInit(32);
    blakejs.blake2bUpdate(context, combinedValue);
    const hash = blakejs.blake2bFinal(context);
    let unsignedInt = 0n;
    for (let i = 0; i < 8; i++) {
      unsignedInt += BigInt(hash[i]) << BigInt(8 * i);
    }

    const unitRangeFloat = Number(unsignedInt) / Number(2n ** 64n - 1n);
    const normalReturn = jstat.normal.inv(unitRangeFloat, 0, volatility.value);
    currentPrice = currentPrice * (1.0 + normalReturn);

    results.push({
      tick: i + 1,
      price: currentPrice.toFixed(2),
    });
  }

  output.value = results;
}

getOutput();

watch([seed, price, volatility, totalTicks,], useDebounceFn(getOutput, 500));

watch([() => props.seed, () => props.volatility, () => props.startPrice,], () => {
  if (props.seed) {
    seed.value = props.seed;
  }

  if (props.startPrice) {
    price.value = props.startPrice;
  }

  if (props.volatility) {
    volatility.value = props.volatility;
  }

  getOutput();
});
</script>
